/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import Seo from '@core/Seo';
import renderRichText from '@common/renderRichText';

import BlockImage from '@components/BlockImage';
import BlockText from '@components/BlockText';
import BackLink from '@components/BackLink';
import withModal from '@common/withModal';
import mq, { mqdown } from '@mq';
import { richText, hyphenate } from '@mixins';

const LocationPage = ({
  modal,
  data: {
    contentfulLocation: {
      city,
      street,
      contentBlocks,
      seoImage: {
        gatsbyImageData: {
          images: {
            fallback: { src: seoImage },
          },
        },
      },
    },
  },
}) => (
  <>
    <Seo pageTitle={`${street}, ${city}`} pageImage={seoImage} />
    {!modal && <BackLink to="/" />}
    <Header>
      <h3>
        {street} {city}
      </h3>
    </Header>
    {contentBlocks && (
      <ContentBlocks>
        {contentBlocks.map(contentBlock => {
          const Component = getBlock(contentBlock.__typename);
          return <Component {...contentBlock} key={contentBlock.id} />;
        })}
      </ContentBlocks>
    )}
  </>
);

const Header = styled.header`
  display: grid;
  row-gap: 0.75rem;
  margin-bottom: var(--space-double);

  ${mq.medium} {
    grid-template-columns: auto auto;
    column-gap: 5rem;
    justify-content: start;
    margin-bottom: 5rem;
  }
`;

const ContentBlocks = styled.div`
  display: grid;
  row-gap: 5rem;

  ${mq.medium} {
    row-gap: 10rem;
  }
`;

function getBlock(typename) {
  switch (typename) {
    case 'ContentfulBlockText':
      return BlockText;
    case 'ContentfulBlockImage':
      return BlockImage;
    default:
      return null;
  }
}

export const query = graphql`
  query ($id: String!) {
    contentfulLocation(id: { eq: $id }) {
      city
      street
      seoImage: image {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: JPG
          quality: 80
          resizingBehavior: FILL
        )
      }
      contentBlocks {
        ... on ContentfulBlockImage {
          __typename
          id
          width
          images {
            description
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 840
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 80
            )
          }
        }
        ... on ContentfulBlockText {
          __typename
          width
          id
          fontSize
          text {
            raw
          }
        }
      }
    }
  }
`;

export default withModal(LocationPage);
